import { DataFilter } from '@/helpers/services/api';
import { capitalizeFirstLetter } from '@/helpers/utils/capitalizeFirstLetter';

export const topicsInsightString = (topic: DataFilter) => {
  if (Array.isArray(topic.value)) {
    if (topic.value.length === 1) {
      return `${capitalizeFirstLetter(topic?.column)} - ${topic.value[0]}`;
    } else {
      const topics = topic.value.join(', ');
      return `${capitalizeFirstLetter(topic?.column)} - ${topics}`;
    }
  } else {
    return '';
  }
};

export const buildDrillDownQuery = (dataFilters: DataFilter[], topics: DataFilter[]) => {
  const dataFiltersConditions = dataFilters
    .map((e, i) => `${i === 0 ? '' : ';'}${e.column},${e.operator},${e.property_type},${e.value}`)
    .join('');
  const dataFilterQuery = dataFiltersConditions ? `insight_conditions=${dataFiltersConditions}` : '';

  const categoricalFilters = topics
    .map((e, i) =>
      e?.property_type
        ? `${i === 0 ? '' : ';'}${e.column},${e.operator},${e.property_type},${Array.isArray(e?.value) ? e?.value.map(e => (e === null ? '\x00' : e)).join(':') : e?.value}`
        : ''
    )
    .join('');
  const categoricalFiltersQuery = categoricalFilters ? `&insight_conditions=${categoricalFilters}` : '';

  const topicsValue = Array?.isArray(topics?.[0]?.value) && topics[0]?.value?.toString();
  const topicsQuery = topics?.[0]?.value ? `${dataFiltersConditions ? '&' : ''}topics=${topicsValue}` : '';

  return `?${dataFilterQuery}${topicsQuery}${categoricalFiltersQuery}`;
};
