import React from 'react';

import { Box, Popover, PopoverProps, styled } from '@mui/material';

interface PropertiesDialogPropertyDropdownProps {
  is_active: string;
  disabled: boolean;
  no_property: string;
}

export const PropertiesDialogPropertyDropdown = styled(Box)<PropertiesDialogPropertyDropdownProps>(
  ({ theme, is_active, disabled, no_property }) => ({
    height: '53px',
    border: is_active === 'true' ? '2px solid' : '1px solid',
    borderColor: is_active === 'true' ? theme.palette.primary.main : theme.palette.grey[300],
    borderRadius: '5px',
    padding: '16.5px 14px',
    cursor: disabled ? 'default' : 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    ':hover': {
      borderColor: disabled ? theme.palette.grey[300] : theme.palette.common.black
    },

    ' p': {
      color: disabled || no_property === 'true' ? theme.palette.grey[500] : theme.palette.common.black
    }
  })
);

export const StyledPopover = styled((props: PopoverProps) => (
  <Popover
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
    slotProps={{ paper: { style: { width: '466px', borderRadius: '16px', padding: '12px 0' } } }}
    {...props}
  />
))({});
