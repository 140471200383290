export const constants = {
  title: 'Score',
  score: 'Score',
  estimated: 'Est.Score',
  latency: 'Avg. Latency',
  latencyTooltip: 'Average latency in seconds, update this field by uploading your data using our SDK.',
  interactions: 'Interactions',
  annotation: 'Annot. %',
  userAnnotation: 'User Annot.',
  estAnnotation: 'Est. Annot.',
  users: 'Users',
  annotated: 'Annotated',
  distribution: {
    estimated: 'Estimated',
    userAnnotated: 'User Annotated'
  },
  changeViewBtnLabel: (isChartView: boolean) => (isChartView ? 'Show Score Breakdown' : 'Show on Graph'),
  usersLight: (numberOfUsers?: number) =>
    numberOfUsers || numberOfUsers === 0 ? `${numberOfUsers} Active Users` : 'Application Summary'
};
