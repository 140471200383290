import React from 'react';

import {
  CategoricalSelectionsContainer,
  PropertyCategoryPercentage
} from './PropertyDrillDownCategoricalSelections.styles';
import { CategoryPropertyValue } from '@/helpers/services/api';
import { Stack } from '@mui/material';
import { StyledToolTip } from '@/components/lib';

interface CategoricalSelectionsProps {
  selectedCategory: string | undefined;
  categories: CategoryPropertyValue[];
  setSelectedCategory: (selectedCategory: string | undefined) => void;
}

const constants = {
  categoryLabel: (category: string | null | undefined, percentage: number, samples: number) =>
    `${Math.round(percentage * 100)}%  ${category === null ? 'N/A' : String(category)} (${samples})`
};

export const PropertyDrillDownCategoricalSelections = (props: CategoricalSelectionsProps) => {
  const { categories, selectedCategory, setSelectedCategory } = props;

  const totalCount = Array?.isArray(categories)
    ? categories?.map(e => e.count)?.reduce((total, value) => total + Number(value), 0)
    : 0;

  return (
    <CategoricalSelectionsContainer data-testid="CategoricalSelectionsContainer">
      {Array.isArray(categories) &&
        categories.map(category => (
          <StyledToolTip key={category?.name} text={category?.name ?? ''}>
            <Stack>
              <PropertyCategoryPercentage
                key={category.name}
                percentage={category.count / totalCount}
                selected={selectedCategory === category.name}
                text={constants.categoryLabel(category.name, category.count / totalCount, category.count)}
                onClick={() => setSelectedCategory(category.name as string)}
              />
            </Stack>
          </StyledToolTip>
        ))}
    </CategoricalSelectionsContainer>
  );
};
