import React, { useEffect, useState } from 'react';

import { RefreshRounded } from '@mui/icons-material';

import { Insight } from './Insight/Insight';
import { NoInsights } from './NoInsights/NoInsights';
import { NewInsightsAvailable } from './NewInsightsAvailable/NewInsightsAvailable';

import { StyledSkeleton, StyledText } from '@/components/lib';
import {
  InsightsContainer,
  InsightsHeaderContainer,
  InsightsRefreshButton,
  InsightsScrollContainer
} from './Insights.styles';

import { usePollingIndicator } from '@/helpers/hooks/usePolling';
import {
  useGetInsightApiV1InsightsGet,
  InsightSchema,
  EnvType,
  recalculateInsightsApiV1InsightsRecalculatePost
} from '@/helpers/services/api';

import { constants } from '../overview.constants';

interface InsightsProps {
  selectedVersionId: number;
  envType: EnvType;
}

export const Insights = ({ selectedVersionId, envType }: InsightsProps) => {
  const [recalculationDisabled, setRecalculationDisabled] = useState(false);

  const { data, refetch, isLoading } = useGetInsightApiV1InsightsGet({ application_version_id: selectedVersionId });
  const { app_latest_update_time, insights } = usePollingIndicator();

  const isProd = envType === EnvType['PROD'];
  const isPenTest = envType === EnvType['PENTEST'];

  const isRecalculateAvailable = insights?.can_execute_calculation && false;
  const filteredData = Array.isArray(data) ? data?.filter(insight => insight?.env_type === envType) : [];

  const handleRecalculationClick = () => {
    recalculateInsightsApiV1InsightsRecalculatePost({
      application_version_id: selectedVersionId,
      environment: envType
    });
    setRecalculationDisabled(true);
  };

  useEffect(() => {
    refetch();
  }, [app_latest_update_time]);

  if (isPenTest || isProd) {
    return <></>;
  } else if (!filteredData?.length && !isLoading) {
    return <NoInsights />;
  }

  return (
    <InsightsContainer data-testid="InsightsContainer">
      <InsightsHeaderContainer>
        <StyledText text={constants.insights.title} type="h2" />
        {isRecalculateAvailable && (
          <InsightsRefreshButton
            disabled={recalculationDisabled}
            label={constants.insights.refreshBtnLabel}
            onClick={handleRecalculationClick}
            startIcon={<RefreshRounded />}
          />
        )}
      </InsightsHeaderContainer>
      <InsightsScrollContainer>
        {isRecalculateAvailable && <NewInsightsAvailable />}
        {isLoading
          ? [...Array(3)].map((_i, index) => <StyledSkeleton key={index} height="155px" width="100%" />)
          : filteredData?.map((insight: InsightSchema, i) => (
              <Insight key={insight?.insight_type + i} insight={insight} refetch={refetch} />
            ))}
      </InsightsScrollContainer>
    </InsightsContainer>
  );
};
