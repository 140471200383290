import { Divider, Stack, styled } from '@mui/material';

export const StyledContainer = styled(Stack)({
  height: '100%',
  width: '100%',
  flexDirection: 'row',
  gap: '24px',
  overflow: 'auto',

  '@media (max-width: 850px)': {
    overflow: 'visible',
    maxHeight: '340px'
  }
});

export const StyledDivider = styled(Divider)(({ theme }) => ({
  border: `0.1px solid ${theme.palette.grey[300]}`
}));
