import React from 'react';

import { useRouter } from 'next/router';

import { BoxProps, Stack, useTheme } from '@mui/material';

import { StyledText, StyledToolTip } from '@/components/lib';
import { StyledPending } from '@/components/Samples/SamplesAnnotations/SamplesAnnotations.styles';

import { routes } from '@/helpers/routes';
import { PropertyConfigSchemaOutput } from '@/helpers/services/api';

import {
  PropertyCategoriesContainer,
  PropertyContainer,
  PropertyContentContainer,
  PropertyContentInnerContainer,
  PropertyOtherPropContainer
} from '../Property/Property.styles';
import { sxContainerStyles, sxStackStyles, sxTextStyles } from './PropertiesPendingAndNACard.styled';

import { constants } from './propertiesPendingAndNACard.constants';

export interface PropertyConfigSchema extends PropertyConfigSchemaOutput {
  order?: string;
}

interface PropertyProps extends BoxProps {
  listOfNAProperties: PropertyConfigSchemaOutput[];
}

const { propertiesNotDisplayed, otherProperties, noValuesAvailable, na } = constants;

export const PropertiesPendingAndNACard = (props: PropertyProps) => {
  const { listOfNAProperties, ...otherProps } = props;

  const { push } = useRouter();
  const { palette } = useTheme();

  const handleRedirectToPropertiesAndTab = (el: PropertyConfigSchemaOutput) => {
    push(`${routes.config.properties}?drillDownPropertyName=${el?.text_property}&drillDownPropertyType=${el?.kind}`);
  };

  return (
    <PropertyContainer {...otherProps} data-testid="PropertiesPendingAndNACard">
      <StyledToolTip
        text={propertiesNotDisplayed}
        descriptions={
          <>
            {noValuesAvailable}
            <Stack sx={sxContainerStyles}>
              {listOfNAProperties.map((el, index) => (
                <Stack key={`${el.text_property}_${el.kind}`} sx={sxStackStyles}>
                  <a
                    style={{
                      fontSize: '12px',
                      fontWeight: 900,
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      color: palette.primary.main
                    }}
                    onClick={handleRedirectToPropertiesAndTab.bind(null, el)}
                  >
                    {el.text_property}
                  </a>
                  {index !== listOfNAProperties.length - 1 && (
                    <StyledText text={','} type="h3" sx={sxTextStyles} color={palette.common.white} />
                  )}
                </Stack>
              ))}
            </Stack>
          </>
        }
      >
        <PropertyContentInnerContainer>
          <PropertyContentContainer>
            <PropertyOtherPropContainer>
              <StyledPending />
              <StyledText text={otherProperties} type="h3" />
            </PropertyOtherPropContainer>
            <PropertyCategoriesContainer data-testid="PropertyCategoriesContainer">
              <StyledText
                text={`${na} ${listOfNAProperties.map(el => el.text_property)[0]} ${
                  listOfNAProperties.length > 1 ? `, +${listOfNAProperties.length - 1}` : ''
                }`}
                type="tinyBold"
              />
            </PropertyCategoriesContainer>
          </PropertyContentContainer>
        </PropertyContentInnerContainer>
      </StyledToolTip>
    </PropertyContainer>
  );
};
