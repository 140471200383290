import React from 'react';

import { StackProps } from '@mui/material';

import { StyledSkeleton } from '@/components/lib';
import { NoDataAvailable } from '@/components/shared/NoDataAvailable/NoDataAvailable';
import { AnnotationAndScore } from '@/components/shared/AnnotationAndScore/AnnotationAndScore';

import { StyledContainer, StyledDivider } from './PropertyDrillDownInteractions.styles';

import { TextScore } from '@/helpers/services/api';

export interface PropertyDrillDownInteractionsProps extends StackProps {
  name: string;
  isFetching: boolean;
  isCategorical: boolean;
  data: TextScore[] | undefined;
  handleOpenDialog: (interactionId: string) => void;
}

export const PropertyDrillDownInteractions = (props: PropertyDrillDownInteractionsProps) => {
  const { name, data, isFetching, isCategorical, handleOpenDialog } = props;

  const showDivider = isCategorical && (isFetching || !data?.length);

  return (
    <StyledContainer data-testid="HighestLowestSamplesContainer">
      {showDivider && <StyledDivider />}
      {isFetching ? (
        <>
          <StyledSkeleton height="100%" width="450px" />
          <StyledSkeleton height="100%" width="450px" />
        </>
      ) : Array.isArray(data) && data?.length ? (
        data?.map(e => (
          <AnnotationAndScore
            key={e.user_interaction_id}
            sample={e}
            propertyName={name}
            handleOpenDialog={handleOpenDialog}
            isDrillDown
          />
        ))
      ) : (
        <NoDataAvailable />
      )}
    </StyledContainer>
  );
};
