import React, { useState } from 'react';

import { useMediaQuery, useTheme } from '@mui/material';

import { EnvType } from '@/helpers/services/api';

import { Topics } from './Topics/Topics';
import { Summary } from './Summary/Summary';
import { Insights } from './Insights/Insights';
import { Properties } from './Properties/Properties';
import { AppFilters } from '../shared/AppFilters/AppFilters';
import { AnnotationOvertimeGraph } from './AnnotationOvertimeGraph/AnnotationOvertimeGraph';
import { OverviewNoVersionSelected } from './OverviewNoVersionSelected/OverviewNoVersionSelected';

import { StyledContainer } from '../lib';
import { OverviewInnerGrid, OverViewPropertiesContainer, SummaryTopicsContainer } from './Overview.styles';

export const Overview = () => {
  const [selectedApp, setSelectedApp] = useState({
    appId: 0,
    versionId: 0,
    type: 'EVAL',
    timestamp: { start: NaN, end: NaN }
  });

  const { breakpoints } = useTheme();

  const isDesktop = useMediaQuery(breakpoints.up('xl'));

  const selectedAppId = selectedApp?.appId;
  const selectedVersionId = selectedApp?.versionId;
  const envType = selectedApp?.type as EnvType;

  const isProd = envType === EnvType['PROD'];
  const isPenTest = envType === EnvType['PENTEST'];

  return (
    <StyledContainer>
      <AppFilters setSelectedApp={setSelectedApp} initialLoading isOverview />
      {!selectedAppId ? null : selectedVersionId ? (
        <OverviewInnerGrid garak={isPenTest}>
          <OverViewPropertiesContainer data-testid="OverViewPropertiesContainer">
            {isProd ? (
              <AnnotationOvertimeGraph
                envType={envType}
                timestamp={selectedApp.timestamp}
                selectedVersionId={selectedVersionId}
              />
            ) : (
              <SummaryTopicsContainer>
                <Summary selectedVersionId={selectedVersionId} envType={envType} />
                <Topics selectedVersionId={selectedVersionId} envType={envType} />
              </SummaryTopicsContainer>
            )}
            {!isDesktop && <Insights selectedVersionId={selectedVersionId} envType={envType} />}
            <Properties
              envType={envType}
              selectedAppId={selectedAppId}
              timestamp={selectedApp?.timestamp}
              selectedVersionId={selectedVersionId}
            />
          </OverViewPropertiesContainer>
          {isProd ? (
            <Topics envType={envType} timestamp={selectedApp.timestamp} selectedVersionId={selectedVersionId} />
          ) : isDesktop ? (
            <Insights selectedVersionId={selectedVersionId} envType={envType} />
          ) : (
            <></>
          )}
        </OverviewInnerGrid>
      ) : (
        <OverviewNoVersionSelected isPenTest={isPenTest} />
      )}
    </StyledContainer>
  );
};
