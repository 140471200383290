import React, { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import dayjs from 'dayjs';

import { SummaryNoData } from './SummaryNoData/SummaryNoData';
import { SummaryAnnotationDistribution } from './SummaryAnnotationDistribution/SummaryAnnotationDistribution';

import { StyledButton, StyledContainer, StyledDoughnutChart, StyledText, StyledToolTip } from '@/components/lib';
import {
  CardContainer,
  SummaryChartContainer,
  SummaryRowContainer,
  SummarySamplesContainer,
  SummaryScoreBigText,
  StyledTitleContainer,
  SummaryDetailsContainer,
  SummaryChartAndDistributionContainer
} from './Summary.styles';

import { routes } from '@/helpers/routes';
import { getPercentage } from '@/helpers/utils/getPercentage';
import { usePollingIndicator } from '@/helpers/hooks/usePolling';
import { EnvType, useGetAppVersionProdSummary } from '@/helpers/services/api';

import { constants } from './summary.constants';

interface SummaryProps {
  selectedVersionId: number;
  envType: EnvType;
}

const { latency, latencyTooltip, interactions, title, userAnnotation, estAnnotation, changeViewBtnLabel } = constants;

export const Summary = ({ selectedVersionId, envType }: SummaryProps) => {
  const [isChartView, setIsChartView] = useState(true);
  const [isIncludeEstimated, setIsIncludeEstimated] = useState(true);

  const { push } = useRouter();
  const { app_latest_update_time } = usePollingIndicator();
  const { data, isLoading, refetch } = useGetAppVersionProdSummary(selectedVersionId, { environment: envType });

  const isPartialDataMode = !data?.latency_seconds;

  useEffect(() => {
    refetch();
  }, [app_latest_update_time]);

  if (!data?.n_of_samples) {
    return <SummaryNoData isLoading={isLoading} />;
  }

  const curAnnotatedBadNum = isIncludeEstimated ? data?.annotated_bad : data?.user_annotated?.bad;
  const curAnnotatedGoodNum = isIncludeEstimated ? data?.annotated_good : data?.user_annotated?.good;
  const curAnnotatedNum = Number(curAnnotatedBadNum) + Number(curAnnotatedGoodNum);

  const allEstimatedAnnotatedNum = Number(data?.estimated?.bad) + Number(data?.estimated?.good);
  const allUserAnnotatedNum = Number(data?.user_annotated?.bad) + Number(data?.user_annotated?.good);

  const calculatedScore = Number(curAnnotatedGoodNum) / Number(curAnnotatedNum);
  const dataToDisplay = {
    estAnnotation: {
      label: estAnnotation,
      value: getPercentage(allEstimatedAnnotatedNum / Number(data?.n_of_samples), true)
    },
    userAnnotation: {
      label: userAnnotation,
      value: getPercentage(allUserAnnotatedNum / Number(data?.n_of_samples), true)
    },
    interactions: { label: interactions, value: data?.n_of_samples },
    latency: {
      label: latency,
      value: data?.latency_seconds ? `${data?.latency_seconds} Sec` : 'N/A',
      tooltip: latencyTooltip
    },
    dates:
      data?.start_date && data?.end_date
        ? `${dayjs(data?.start_date).format('MMM DD YYYY')} - ${dayjs(data?.end_date).format('MMM DD YYYY')}`
        : ''
  };

  const chartData = [
    curAnnotatedBadNum || 0,
    curAnnotatedGoodNum || 0,
    isIncludeEstimated ? Number(data?.unannotated) : allEstimatedAnnotatedNum + Number(data?.unannotated)
  ];

  const handleCenterClick = () => setIsIncludeEstimated(!isIncludeEstimated);

  const handleRedirect = (annotation: string) => {
    annotation === 'unknown'
      ? push(`${routes.samples}?annotations=unknown${isIncludeEstimated ? '%3Bpending' : ''}`)
      : push(
          `${routes.samples}?annotations=${
            isIncludeEstimated ? `${annotation}%2Cestimated%3B` : ''
          }${annotation}%2Cuser_annotated`
        );
  };

  return (
    <CardContainer type="card" data-testid="SummaryContainer">
      <SummaryChartAndDistributionContainer>
        <StyledTitleContainer>
          <StyledText type="h2" text={title} />
          <StyledText type="tinyBold" text={dataToDisplay.dates} />
        </StyledTitleContainer>
        <SummaryChartContainer>
          {isChartView ? (
            <StyledDoughnutChart
              data={chartData}
              score={calculatedScore}
              userAnnotated={data?.user_annotated}
              estimatedAnnotated={data?.estimated}
              isIncludeEstimated={isIncludeEstimated}
              pendingAnnotated={Number(data?.pending_annotations)}
              checkboxCheckedIcon="./assets/chart/select-all-checkbox.svg"
              checkboxUnCheckedIcon="./assets/chart/select-all-checkbox-unchecked.svg"
              handleCenterClick={handleCenterClick}
              actions={{
                badRedirect: () => handleRedirect('bad'),
                goodRedirect: () => handleRedirect('good'),
                unannotatedRedirect: () => handleRedirect('unknown')
              }}
            />
          ) : (
            <SummaryAnnotationDistribution summaryData={data} handleRedirect={handleRedirect} />
          )}
        </SummaryChartContainer>
      </SummaryChartAndDistributionContainer>
      <SummaryDetailsContainer>
        <StyledButton
          variant="outlined"
          label={changeViewBtnLabel(isChartView)}
          onClick={() => setIsChartView(!isChartView)}
        />
        <SummaryRowContainer withBorder isColumn>
          <SummarySamplesContainer onClick={() => push(routes.samples)}>
            <SummaryScoreBigText type="h1" text={dataToDisplay.interactions.value} />
            <StyledText type="tinyBold" text={dataToDisplay.interactions.label} />
          </SummarySamplesContainer>
          <SummaryRowContainer>
            <StyledContainer>
              <SummaryScoreBigText type="h1" text={dataToDisplay.userAnnotation.value} />
              <StyledText type="tinyBold" text={dataToDisplay.userAnnotation.label} />
            </StyledContainer>
            <StyledContainer>
              <SummaryScoreBigText type="h1" text={dataToDisplay.estAnnotation.value} />
              <StyledText type="tinyBold" text={dataToDisplay.estAnnotation.label} />
            </StyledContainer>
          </SummaryRowContainer>
        </SummaryRowContainer>
        {!isPartialDataMode && (
          <SummaryRowContainer withBorder>
            {data?.latency_seconds && (
              <StyledToolTip text={dataToDisplay.latency.tooltip}>
                <StyledContainer>
                  <StyledText type="h2" text={dataToDisplay.latency.value} />
                  <StyledText type="tinyBold" text={dataToDisplay.latency.label} />
                </StyledContainer>
              </StyledToolTip>
            )}
          </SummaryRowContainer>
        )}
      </SummaryDetailsContainer>
    </CardContainer>
  );
};
