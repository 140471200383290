import { Over } from '@dnd-kit/core';
import { PropertyConfigSchema } from './Property/Property';

export const handleChangeOrderOfProperties = (
  properties: PropertyConfigSchema[],
  active: { id: string | number },
  over: Over | null
) =>
  properties
    .map((el: PropertyConfigSchema) => {
      if (el.order === active.id) {
        return { ...el, order: over?.id };
      }

      if (
        el.order &&
        +el.order !== +active.id &&
        +(over?.id || 0) > +active.id &&
        +el.order <= +(over?.id || 0) &&
        +el.order > +active.id
      ) {
        return { ...el, order: `${+el.order - 1}` };
      }

      if (
        el.order &&
        +el.order !== +active.id &&
        +(over?.id || 0) < +active.id &&
        +el.order >= +(over?.id || 0) &&
        +el.order < +active.id
      ) {
        return { ...el, order: `${+el.order + 1}` };
      }

      return el;
    })
    .sort((a, b) => (+(a.order || 0) < +(b.order || 0) ? -1 : 1));
