import React from 'react';

import { StyledSkeleton, StyledText } from '@/components/lib';

import { OverviewNoDataAvailable } from '../../OverviewNoDataAvailable/OverviewNoDataAvailable';

import { CardContainer, CardDescriptionContainer } from '../Summary.styles';

interface AnnotationsNoDataProps {
  isLoading: boolean;
}

const constants = {
  title: 'Score',
  skeletonHeight: '418px'
};

const { skeletonHeight, title } = constants;

export const SummaryNoData = ({ isLoading }: AnnotationsNoDataProps) => {
  if (isLoading) {
    return <StyledSkeleton height={skeletonHeight} />;
  }

  return (
    <CardContainer type="card">
      <CardDescriptionContainer>
        <StyledText text={title} type="h2" />
        <OverviewNoDataAvailable />
      </CardDescriptionContainer>
    </CardContainer>
  );
};
