import React, { useEffect, useState } from 'react';

import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';
import { StyledDialog, StyledOutlinedDropdown, StyledText } from '@/components/lib';
import { SamplesTablePopoverSortByProperty } from '@/components/Samples/SamplesTable/SamplesTablePopover/SamplesTablePopoverSortByProperty/SamplesTablePopoverSortByProperty';

import { PropertiesDialogPropertyDropdown, StyledPopover } from './AddOrEditPropertyDialog.styles';
import { PropertiesDialogInnerContainer, PropertiesDialogTextInput, PropertiesErrText } from '../Properties.styles';

import { resError } from '@/helpers/services/resHandlers';
import { getAppStorage } from '@/helpers/utils/localStorage';
import { propertyConditionsDropdownValues } from './addOrEditPropertyDialog.helpers';
import {
  PropertyConfigSchemaOutput,
  Operator,
  OrderByModel,
  PropertyColumnType,
  PropertyDefinitionSchema,
  PropertyType,
  createOrUpdatePropertyConfig,
  useListPropertiesDefinitions
} from '@/helpers/services/api';

import { constants } from '../../overview.constants';

export interface AddOrEditPropertyDialogProps {
  open: boolean;
  closeMenu?: () => void;
  selectedAppId: number;
  propertyData?: PropertyConfigSchemaOutput;
  closeDialog: () => void;
  refetchProperties: () => void;
}

const { titleAndSubmit, conditionLabel, thresholdConst, error } = constants.properties.dialog;

export const AddOrEditPropertyDialog = (props: AddOrEditPropertyDialogProps) => {
  const { open, closeDialog, selectedAppId, refetchProperties, propertyData, closeMenu } = props;

  const { appId, type } = getAppStorage();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [err, setErr] = useState('');
  const [loading, setLoading] = useState(false);
  const [kind, setKind] = useState<string | PropertyType>(propertyData?.kind || '');
  const [condition, setCondition] = useState<string | number>(propertyData?.condition || '');
  const [property, setProperty] = useState<string | number>(propertyData?.text_property || '');
  const [threshold, setThreshold] = useState(propertyData?.threshold?.toString() ?? '');

  const { data: propertylist, refetch } = useListPropertiesDefinitions(selectedAppId, { enviroment: type });

  const disabledSubmit = propertyData?.threshold === +threshold && propertyData?.condition === condition;
  const selectedProperty = Array.isArray(propertylist)
    ? propertylist?.filter(p => p?.property_name === property && p?.property_type === kind)[0]
    : ({} as PropertyDefinitionSchema);

  const isEditMode = !!propertyData?.id;
  const isCategorical = selectedProperty?.column_type === PropertyColumnType['categorical'];

  const handleCloseDialog = () => {
    closeDialog();
    setProperty('');
    setKind('');
    setThreshold('');
    setCondition('');
    setAnchorEl(null);
  };

  const filteredPropertylist = Array.isArray(propertylist)
    ? propertylist?.filter(property => propertyData || !property.in_dashboard)
    : [];

  const submitButtonAction = async () => {
    if (!property || !kind) {
      setErr(error);
    } else {
      setLoading(true);

      const handleFinishCall = () => {
        setErr('');
        handleCloseDialog();
        setThreshold('');
        setLoading(false);
        refetchProperties();
        setKind(propertyData?.kind || '');
        setCondition(propertyData?.condition || '');
        setProperty(propertyData?.text_property || '');
        closeMenu && closeMenu();
      };

      await createOrUpdatePropertyConfig({
        application_id: appId,
        kind: kind as PropertyType,
        text_property: `${property}`,
        condition: (condition as Operator) || null,
        threshold: Number(threshold),
        in_dashboard: true,
        is_hidden: false
      }).then(res => {
        if ((res as resError)?.error_message) {
          setErr((res as resError)?.error_message);
          setLoading(false);
        } else {
          handleFinishCall();
        }
      });
    }
  };

  const handleApplyClick = (newKind: OrderByModel, newProperty?: string) => {
    setKind(newKind.split('_properties')[0]);

    if (newProperty) {
      setProperty(newProperty);
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    if (open) {
      refetch();
      if (propertyData) {
        setProperty(propertyData.text_property);
        setKind(propertyData.kind);
        setThreshold(propertyData?.threshold?.toString() ?? '');
        setCondition(propertyData.condition || '');
      }
    }
  }, [open]);

  return (
    <StyledDialog
      open={open}
      closeDialog={handleCloseDialog}
      title={titleAndSubmit(isEditMode)}
      submitButtonLabel={titleAndSubmit(isEditMode)}
      submitButtonAction={submitButtonAction}
      submitButtonDisabled={disabledSubmit}
      submitButtonWidth="150px"
      isLoading={loading}
    >
      <PropertiesDialogInnerContainer>
        <PropertiesDialogPropertyDropdown
          is_active={Boolean(anchorEl).toString()}
          disabled={isEditMode}
          onClick={e => !isEditMode && setAnchorEl(e.currentTarget)}
          no_property={!property ? 'true' : 'false'}
          data-testid="AddPropertySelect"
        >
          <StyledText text={property || 'Choose property'} />
          {property && <InOutIcon kind={kind as PropertyType} />}
        </PropertiesDialogPropertyDropdown>
        <StyledPopover open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
          <SamplesTablePopoverSortByProperty
            model={`${kind}_properties` as OrderByModel}
            column={property as string}
            handleApplyClick={handleApplyClick}
            propertylist={filteredPropertylist || []}
          />
        </StyledPopover>

        {!isCategorical && (
          <>
            <StyledOutlinedDropdown
              label={conditionLabel}
              value={condition || ''}
              data={propertyConditionsDropdownValues}
              setValue={setCondition}
            />
            <PropertiesDialogTextInput
              value={threshold}
              label={thresholdConst.label}
              placeholder={thresholdConst.placeholder(
                Number(selectedProperty?.min_value),
                Number(selectedProperty?.max_value)
              )}
              onChange={e => setThreshold(e.target.value)}
            />
          </>
        )}
        {err && <PropertiesErrText text={err} type="bodyBold" />}
      </PropertiesDialogInnerContainer>
    </StyledDialog>
  );
};
