import { styled } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

export const CategoricalSelectionsContainer = styled(StyledContainer)({
  gap: '24px',
  display: 'grid',
  padding: '0 8px',
  borderRadius: 0,
  overflow: 'auto',
  height: 'fit-content',
  maxHeight: '100%',
  marginRight: '12px',
  gridTemplateColumns: '1fr 1fr'
});

interface PropertyCategoryPercentageProps {
  percentage: number;
  selected?: boolean;
}

export const PropertyCategoryPercentage = styled(StyledText, {
  shouldForwardProp: prop => prop !== 'percentage' && prop !== 'selected'
})<PropertyCategoryPercentageProps>(({ percentage, selected, theme }) => ({
  width: '150px',
  height: '50px',
  fontWeight: 700,
  padding: '16px',
  fontSize: '12px',
  cursor: 'pointer',
  lineHeight: '16px',
  textAlign: 'center',
  whiteSpace: 'nowrap',
  borderRadius: '10px',
  border: `1px solid ${theme.palette.common.white}`,
  transition: '0.2s',
  color: selected ? theme.palette.common.white : theme.palette.grey[600],
  background: selected
    ? theme.palette.primary.main
    : `linear-gradient(to right, ${theme.palette.grey[200]} ${percentage * 100}%, ${theme.palette.grey[100]} ${
        percentage * 100
      }%)`,

  ':hover': { borderColor: theme.palette.primary.main }
}));
