import { styled } from '@mui/material';

import { StyledContainer, StyledInput, StyledLoader, StyledText } from '@/components/lib';

export const PropertiesContainer = styled(StyledContainer)(() => ({
  gap: '24px',
  width: '100%',
  padding: '24px'
}));

export const PropertiesHeaderContainer = styled(StyledContainer)(() => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '0 0 0 6px',
  alignItems: 'center',
  '& svg': {
    cursor: 'pointer',
    color: '#C8C9DF'
  }
}));

export const PropertiesInnerContainer = styled(StyledContainer)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '16px',
  padding: '0',

  [theme.breakpoints.down('xl')]: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))'
  }
}));

export const PropertiesDialogInnerContainer = styled(StyledContainer)(() => ({
  gap: '36px'
}));

export const PropertiesDialogTextInput = styled(StyledInput)(({ theme }) => ({
  height: '52px',
  color: theme.palette.common.black
}));

export const PropertiesAddCardText = styled(StyledText)(({ theme }) => ({
  margin: 'auto',
  color: theme.palette.grey[400]
}));

export const LoaderContainer = styled(StyledContainer)(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  height: '30vh'
}));

export const PropertiesLoader = styled(StyledLoader)(() => ({
  margin: '200px auto'
}));

export const PropertiesErrText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.error.main,
  maxWidth: '466px'
}));
