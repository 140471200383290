import React from 'react';

import { BoxProps } from '@mui/material';

import { InOutIcon } from '@/components/shared/InOutIcon/InOutIcon';
import { PropertyContent } from './PropertyContent/PropertyContent';

import { StyledToolTip } from '@/components/lib';
import {
  PropertyActionsContainer,
  PropertyContainer,
  PropertyContentInnerContainer,
  PropertyScoreColor
} from './Property.styles';

import { useScoreColor } from '@/helpers/hooks/useScoreColor';
import {
  CategoryPropertyValue,
  Operator,
  PropertyConfigSchemaOutput,
  PropertyDefinitionSchema
} from '@/helpers/services/api';

import { constants } from '../../overview.constants';

interface StaticDndPropertyProps extends BoxProps {
  score: number | CategoryPropertyValue[] | undefined;
  propertyData: PropertyConfigSchemaOutput;
  propertyInfo: PropertyDefinitionSchema;
}

export const StaticDndProperty = (props: StaticDndPropertyProps) => {
  const { score, propertyData, propertyInfo, ...otherProps } = props;

  const scoreColor = useScoreColor(Number(score), propertyData?.condition as Operator, Number(propertyData?.threshold));

  return (
    <PropertyContainer {...otherProps}>
      <StyledToolTip
        text={propertyInfo.description}
        link={{ href: propertyInfo.url, label: constants.properties.propertyMenu.linkToDocs }}
      >
        <PropertyContentInnerContainer>
          <PropertyContent propertyData={propertyData} score={score} scoreDynamicColor={scoreColor} />
        </PropertyContentInnerContainer>
      </StyledToolTip>
      <PropertyActionsContainer>
        <PropertyScoreColor background={scoreColor} />
        <InOutIcon kind={propertyData?.kind} margin="0 0 0 auto" />
      </PropertyActionsContainer>
    </PropertyContainer>
  );
};
