export const constants = {
  properties: {
    title: 'Properties',
    addCardLabel: '+ Add Property',
    dialog: {
      titleAndSubmit: (isEditMode: boolean) => (isEditMode ? 'Edit Property' : 'Add Property'),
      propertyLabel: 'Property (Required)',
      conditionLabel: 'Condition',
      thresholdConst: {
        label: 'Threshold',
        placeholder: (min?: number, max?: number) => `${min || 0} to ${max || 'inf'}`
      },
      kindLabel: 'Type (Required)',
      error: 'Please fill all the required fields'
    },
    propertyMenu: {
      linkToDocs: 'More Details',
      info: 'Info',
      condition: 'Condition',
      remove: 'Remove From Overview'
    }
  },
  insights: {
    title: 'Insights',
    samples: 'Interactions',
    dismissed: 'Insight Dismissed',
    undoButtonLabel: 'Undo',
    refreshBtnLabel: 'Recalculate',
    analysis: {
      analysis: 'Analysis:',
      suggestions: 'Suggestions:',
      properties: 'Properties:',
      buttonLabel: 'View Data >'
    },
    weakSegment: {
      score: 'Score:',
      buttonLabel: 'View Segment >'
    },
    recalculateAvailable: {
      imgAlt: 'sand-clock-illustration',
      title: 'New Segments Available',
      description: 'Click the recalculate button to refresh Segments'
    },
    noInsights: {
      description: 'No insights for the current interactions'
    }
  },
  topics: {
    title: 'Topics',
    inputPlaceholder: 'Search topic...',
    refreshBtnLabel: 'Recalculate Topics',
    edit: {
      edit: 'Edit',
      name: 'Name',
      description: 'Description',
      submitButtonLabel: 'Save',
      title: 'Edit Topic Details'
    },
    noTopics: {
      imgAlt: 'eye-illustration',
      title: 'No Data Available',
      description: 'We need more interactions to calculate topics'
    },
    inProgress: {
      imgAlt: 'sand-clock-illustration',
      title: 'Topic Calculation In Progress',
      description: 'Please come back soon'
    }
  }
};
